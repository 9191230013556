import { Route, Redirect } from 'react-router-dom';

import { MainLayout } from 'Layouts';
import { isNil } from 'lodash';

function PublicRoute({
	component: Component,
	layout: Layout = MainLayout,
	...rest
}) {
	const authenticated = true
	if (isNil(authenticated)) {
		return null;
	}

	return (
		<Route
			{...rest}
			render={(props) =>
				!authenticated ? (
					<Layout>
						<Component {...props} />
					</Layout>
				) : (
					<Redirect to={{ pathname: '/', state: {} }} />
				)
			}
		/>
	);
}

export default PublicRoute;
