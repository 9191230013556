import React, { Fragment } from "react";
import { Route } from "react-router-dom";
import PrivateRoutes from "Routes/PrivateRoutes";
import { MainLayout } from "Layouts";

function CustomRoute({
  component: Component,
  layout: Layout = MainLayout,
  isProtected,
  isLogin,
  ...rest
}) {
  const Protected = isProtected ? PrivateRoutes : Fragment;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Protected>
          <Layout>
            <Component {...props} />
          </Layout>
        </Protected>
      )}
    />
  );
}

export default CustomRoute;
