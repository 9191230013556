import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.white};
	height: ${({ height }) => height};
	border-radius: 1.75rem;
	margin: 1rem 0;

	.table-head {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		.table-head-left {
			.content {
				display: flex;
				align-items: center;
				svg.svg-icon--material {
					stroke: #4e68f9;
				}
				.title {
					font-size: 1rem;
					font-weight: 600;
				}
				.record-counting {
					font-weight: 500;
					margin-left: 0.5rem;
					font-size: 0.6rem;
					opacity: 0.5;
				}
			}
		}
		.table-head-right {
			.create {
				color: #46bcaa;
				background-color: #edf8f7;
				border: 1px solid #edf8f7;
				font-weight: 500;
				font-size: 1rem;
				border-radius: 0.75rem;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
					border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				&:hover {
					color: #fff;
					background-color: #46bcaa;
					border-color: #46bcaa;
				}
			}
			.order {
				color: #4d69fa;
				background-color: #edf0ff;
				border: 1px solid #edf0ff;
				font-weight: 500;
				font-size: 1rem;
				border-radius: 0.75rem;
				transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
					border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				&:hover {
					color: #fff;
					background-color: #4d69fa;
					border-color: #4d69fa;
				}
			}
			.more {
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #e7eef8;
				border-color: #e7eef8;
				border-radius: 0.75rem;
				&:hover {
					color: #1f2128;
					background-color: #ebf1f9;
					border-color: #e9f0f9;
				}
				svg {
					width: 15px;
					height: 15px;
				}
			}
		}
	}

	.table-foot {
		display: flex;
		justify-content: space-between;
		margin-top: 1rem;
		.table-foot-left {
			.info {
				font-size: 0.75rem;
				font-weight: 300;
			}
		}
		.table-foot-right {
			.ant-pagination {
				.ant-pagination-item {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-radius: 0;
					font-size: 0.75rem;
					& > a:hover {
						color: #564aa9;
						background-color: #e5e9ed;
					}
				}
				.ant-pagination-prev {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-top-left-radius: 1rem;
					border-top-right-radius: 0;
					border-bottom-left-radius: 1rem;
					border-bottom-right-radius: 0;
					&:hover {
						background-color: #e9ecef;
					}
					button {
						justify-content: center;
						align-items: center;
						background-color: transparent;
						border: none;
						border-radius: 0;
						& svg {
							width: 8px;
							height: 8px;
						}
						&:not(:disabled):hover {
							svg {
								fill: #564aa9;
							}
						}
					}
				}
				.ant-pagination-next {
					margin-right: 0;
					background-color: #e9ecef;
					border-color: #e9ecef;
					border-top-left-radius: 0;
					border-top-right-radius: 1rem;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 1rem;
					&:hover {
						background-color: #e9ecef;
					}
					button {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: transparent;
						border: none;
						border-radius: 0;
						& svg {
							width: 8px;
							height: 8px;
						}
						&:not(:disabled):hover {
							svg {
								fill: #564aa9;
							}
						}
					}
				}
				.ant-pagination-item-active {
					background-color: #6c5dd3;
					border-color: #6c5dd3;
					z-index: 3;
					& > a {
						color: #fff;
						&:hover {
							background-color: #6c5dd3;
							border-color: #6c5dd3;
							color: #fff;
						}
					}
				}
			}
		}
	}
`;

const TableStyle = styled(Table)`
	overflow: auto;
	height: 100%;
	.ant-pagination {
		display: flex;
		justify-content: center;
	}

	.ant-table {
		border-radius: 0.75rem;
		max-height: 90%;
		th{
			padding: 0 !important;
			color: #6D737A !important;
			font-weight: 500;
			font-size: 12px;
			line-height: 30px;
		}

		tbody{
			/* min-height: 28px !important; */
			td{
				/* padding: 0 !important; */
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
			}
		}
		
		.ant-table-tbody > tr > td {
			height: 5px;
			padding: 4px;
		}

		.ant-table-cell::before{
				background-color: transparent !important;
				content: "" !important;
			}

			.ant-table-content {
				border: 1px solid #c9c9c9;
				border-radius: 0.75rem;
				th {
					background-color: #f5f5f5;
					border-right: 1px solid #c9c9c9 !important;
					&:last-child {
						border-right: none !important;
					}
				}
				td {
					border-right: 1px solid #c9c9c9 !important;
					&:last-child {
						border-right: none !important;
					}
				}
			}
	}
`;

function TableScoreComponent({
	dataSource,
	columns,
	currentPage,
	total = 0,
	selected,
	pageSizeOptions,
	pageSize = 20,
	pagination = true,
	breadcrumb,
	action,
	createText,
	orderText,
	onChange,
	onCreate,
	onOrder,
	onDelete,
	height = 'auto',
	titleTable,
	...rest
}) {
	const [t] = useTranslation('student');

	return (
		<Wrapper height={height}>
			<div className="table-head">
				<div className="table-head-left">
					<div className="content">
						<strong className="title">
							<span>
								&nbsp;
								{titleTable}
							</span>
							{/* <small className="record-counting">
								<b>
									Item:
									{selected > 0 && `${selected} /`} {total}
								</b>
							</small> */}
						</strong>
					</div>
				</div>
			</div>
			<TableStyle
				scroll={{ x: '100%' }}
				dataSource={dataSource}
				columns={columns}
				pagination={false}
				onChange={onChange}
				bordered
				locale={{ emptyText:t("no_data")}}
				{...rest}
			/>
		</Wrapper>
	);
}
export default TableScoreComponent
;
